.dropdown-menu {
  padding: 0.3rem 0;
  margin: 0.5rem 0 0;
  border-radius: 0 0 0.5rem 0.5rem;
}

.logo {
  width: 185px;
  height: 3rem;
  background-repeat: no-repeat;
  background-size: cover;
}

.marker-50 {
  width: 0;
  height: 100%;
  border-left: 1px solid #333;
  display: block;
  top: -25%;
  position: absolute;
  left: 50%;
}

s .spinner {
  position: absolute;
  display: flex;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background-color: #e7e6e6;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.rc-slider {
  height: 16px;
  padding: 6px 0;
}

.rc-slider-track,
.rc-slider-disabled {
  background-color: transparent !important;
}

.rc-slider-rail {
  background-color: #333 !important;
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 6px;
  vertical-align: middle;
}

.print-page {
  page-break-before: always;
  width: 297mm !important;
  height: 209mm !important;
  max-width: 100%;
  padding: 15px;
}

.xy-achis > table {
  width: 100%;
  float: unset;
  min-width: initial;
}

.xy-achis {
  font-size: 0.9em;
}

.info-page .info-label {
  max-width: 19%;
  margin-bottom: 0 !important;
}
.info-page .info-desc {
  min-width: 81%;
  margin-bottom: 0 !important;
}
.info-page > .row {
  margin-bottom: 0.2em !important;
  font-size: 11pt;
}
.info-page > .row:first-child {
  margin-bottom: 1.2em !important
}

.matrix-box {
  height: 100%;
  padding: 1.2em 0.5em;
}

.hand{
  cursor: pointer;
}

@media (min-width: 768px) {
  .xy-achis {
    bottom: 70px;
  }
  .xy-achis > table {
    width: 85%;
    min-width: 420px;
    height: 200px;
    float: right;
  }
}

@media print {
  .xy-achis {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .scaleLabel {
    order: -1;
    text-align: center !important;
    margin-bottom: 1em;
  }
  .print-page {
    width: 100%;
    height: auto;
  }
}
